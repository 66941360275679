<template>
  <div class="learner-container">
    <div class="header">
      <h1 class="learn-title">
        {{ active_data.attributes.learning_object.attributes.name }}
      </h1>
      <h2
        class="learn-info"
        v-html="active_data.attributes.learning_object.attributes.description"
      ></h2>
    </div>
    <div class="email-area" v-if="emailChain.length > 0">
      <div class="email-container">
        <div
          class="email-chain"
          v-for="(email, index) in emailChain"
          :key="email.id"
        >
          <div class="email-header">
            <div class="d-flex">
              <p class="character-to">To:</p>
              <div v-if="index % 2 === 0">
                <p class="character-name">{{ emailToCharacter }}</p>
              </div>
              <div v-if="index % 2 !== 0">
                <p class="character-name">{{ emailFromCharacter }}</p>
              </div>
            </div>
            <div class="d-flex">
              <p class="character-from">From:</p>
              <div v-if="index % 2 === 0">
                <p class="character-name">{{ emailFromCharacter }}</p>
              </div>
              <div v-if="index % 2 !== 0">
                <p class="character-name">{{ emailToCharacter }}</p>
              </div>
            </div>
            <p v-if="index === 0 && emailccCharacters.length >= 1">CC: {{ emailccCharacters }}</p>
          </div>
          <div class="email-body" v-if="email.message">
            <p v-if="emailChain.length > 0 || emailSubject !== ''" class="email-subject">Subject: {{ emailSubject }}</p>
            <div v-html="email.message"></div>
          </div>
          <div class="email-body" v-if="email.response">
            <p v-if="emailChain.length > 0 || emailSubject !== ''" class="email-subject">Subject: {{ emailSubject }}</p>
            <div style="white-space: pre-line" v-html="email.response"></div>
          </div>
          <div class="email-divider" v-if="index + 1 !== emailChain.length">
            <div>Reply from {{ email.toCharacter }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="email-entry">
      <input
        class="subject-line"
        type="text"
        v-model="emailSubject"
        placeholder="Add Subject"
        v-if="emailChain.length === 0"
      />
      <div class="email-body" v-if="!userCannotSendEmail">
        <vue-editor
          placeholder="Add Email Body"
          v-model="emailBody"
          :editorToolbar="simpleToolbar"
          @ready="onEditorReady"
        ></vue-editor>
      </div>
      <div class="email-footer">
        <p class="warning-text" v-if="userCanSendOneMoreEmail">This will be your final message in this interaction.</p>
        <button class="btn primary email-button" @click="sendEmail" v-if="!userCannotSendEmail">
          Send
        </button>
        <p class="complete-text" v-if="userCannotSendEmail">You have reached the end of this interaction.</p>
        <p class="complete-text" v-if="userCannotSendEmail">Please click "Get Conversation Assessment" to move forward.</p>
      </div>
    </div>
    <div class="button-container">
      <button
        class="button"
        v-if="hasStarted && user_roles.includes('testing-internal')"
        @click="resetLlmConversation"
      >
        Reset chat history
      </button>
      <button
        class="button"
        v-if="reattemptsEnabled"
        @click="retryInteraction"
      >
        Retry Interaction
      </button>
      <button
        v-if="hasStarted || llmComplete"
        :disabled="loading || assessmentLoading"
        class="button"
        @click="submitAssessmentRequest"
      >
        Get Conversation Assessment
      </button>
      <lo-buttons
        v-if="llmComplete"
        text="Next"
        :nextMethod="next"
        :showCertificateOfCompletion="
          active_data.attributes.enable_certificate_of_completion
        "
      ></lo-buttons>
    </div>
    <llm-feedback-modal></llm-feedback-modal>
  </div>
</template>

<script>
import { utilFunctionService } from "@/utils/utils.service";
import api from "../services/api";
import loButtons from "./loButtons.vue";
import llmFeedbackModal from "./llmFeedbackModal.vue";
import { VueEditor } from "vue2-editor";
export default {
  name: "llm-email",
  props: ["active_data", "nextStatus", "user_roles"],
  components: {
    loButtons,
    llmFeedbackModal,
    VueEditor,
  },
  data() {
    return {
      messages: [
        {
          question: "",
          answer: "",
          response: "",
        },
      ],
      userAnswer: "",
      loading: false,
      assessmentLoading: false,
      feedback: "",
      hasStarted: false,
      finishedLoading: false,
      numberOfAttempts: 0,
      attemptsLimit: 3,
      llmComplete: false,
      showAssessment: false,
      showFeedback: false,
      dialogicQuestions: [],
      llmChatImageEnabled: false,
      llmChatImageURL: "",
      emailBody: "",
      emailSubject: "",
      emailChain: [],
      emailToCharacter: "",
      emailToCharacterJob: "",
      emailFromCharacter: "",
      emailIterationsLimit: 0,
      simpleToolbar: [["bold", "italic", "underline"]],
      studentName: "",
      characterName: "",
      emailccCharacters: [],
      evaluationId: "",
      reattemptsEnabled: false,
      maxAttempts: 0,
      disablePastingText: true,
    };
  },
  computed: {
    userCannotSendEmail() {
      const emailLengthLimit = this.emailChain.filter(email => !email.flagged).length / 2;
      if (this.emailIterationsLimit === 0)
        return false;
      else {
        if (emailLengthLimit >= this.emailIterationsLimit) {
          return true;
        } else {
          return false;
        }
      }
    },
    userCanSendOneMoreEmail() {
      const emailLengthLimit = this.emailChain.filter(email => !email.flagged).length / 2;
      if (emailLengthLimit == (this.emailIterationsLimit - 1)) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    handleCopyPaste(event) {
			console.log(this.disablePastingText);
			if (this.disablePastingText === true) {
				event.preventDefault();
				utilFunctionService.preventCopyPaste(event);
			}
		},
    onEditorReady(editor) {
      console.log("*** editor", editor);
      editor.root.addEventListener('copy', this.handleCopyPaste);
      editor.root.addEventListener('paste', this.handleCopyPaste);
    },
    sendEmail() {
      let userDetail = this.active_data.attributes.user_section.attributes.user;
      this.$cable.subscribe({
        channel: "AssessmentAnswerChannel",
        room: userDetail.id,
      });
      this.emailChain.push({
        fromCharacter: this.studentName,
        toCharacter: this.characterName,
        message: this.emailBody,
        response: "",
        id: this.emailChain.length + 1,
      });
    },
    submitAssessmentRequest() {
      let userDetail = this.active_data.attributes.user_section.attributes.user;
      console.log(this.evaluationId);
      if (this.evaluationId) {
        utilFunctionService.showLoader();
        this.assessmentLoading = true;
        api.getLlmEmailAssessment(this.evaluationId).then((res) => {
          let cardDetail = this.active_data.attributes.learning_object.attributes.card_detail;
          console.log(res);
          this.showFeedback = true;
          this.showAssessment = false;
          this.assessmentLoading = false;
          this.loading = false
          this.$modal.show("llmFeedbackModal", { feedback: res.data.data.attributes, llm_type: cardDetail.llm_type });
          this.$cable.unsubscribe("AssessmentResultChannel");
          utilFunctionService.hideLoader();
        });
      } else {
        this.$modal.show('dialog', {
          title: 'Are you sure? Once your assessment is generated, you will not be able to send any more messages in this conversation.',
          buttons: [
            {
              title: 'Cancel',
              handler: () => {
                this.$modal.hide('dialog')
              }
            },
            {
              title: 'Submit',
              handler: () => {
                this.$modal.hide('dialog')
                this.assessmentLoading = true;
                utilFunctionService.showLoader();
                this.$cable.subscribe({
                  channel: "AssessmentResultChannel",
                  room: userDetail.id,
                });
              }
            },
          ]
        })
      }
    },
    resetLlmConversation() {
      let userDetail = this.active_data.attributes.user_section.attributes.user;
      let payload = {
        student_id: userDetail.id,
        lo_id: this.active_data.attributes.learning_object_id,
        user_learn_obj_id: this.active_data.id
      };
      api.resetLlmConversation(payload).then((res) => {
        console.log(res);
        utilFunctionService.showSuccess("Reset Successfully. Page will refresh in 2 seconds.");
        this.assessmentLoading = false;
        setTimeout(() => {
          location.reload();
        }, 2000);
      });
    },
    next() {
      this.$parent.next();
    },
    getLlmConversation() {
      let payload = {
        lo_id: this.active_data.attributes.learning_object_id,
        user_learn_obj_id : this.active_data.id
      };
      api.getLlmChatHistory(payload).then((res) => {
        let chatHistoryData = res.data.data;
        chatHistoryData.forEach((chatEl) => {
          this.hasStarted = true;
          let response = "";
          if (chatEl.attributes.response.length > 0) {
            response = chatEl.attributes.response[0].response;
          }
          this.emailChain.push({
            fromCharacter: this.studentName,
            toCharacter: this.characterName,
            message: chatEl.attributes.body,
            subject: chatEl.attributes.subject,
            response: "",
            id: this.emailChain.length + 1,
            flagged: chatEl.attributes.is_flagged
          });
          this.emailChain.push({
            fromCharacter: this.characterName,
            toCharacter: this.studentName,
            subject: chatEl.attributes.subject,
            message: "",
            response: response,
            id: this.emailChain.length + 1,
            flagged: chatEl.attributes.is_flagged
          });
        });
      });
      this.assessmentLoading = false;
    },
    refreshLlmConversation() {
      let payload = {
        lo_id: this.active_data.attributes.learning_object_id,
        user_learn_obj_id : this.active_data.id
      };
      api.getLlmChatHistory(payload).then((res) => {
        let chatHistoryData = res.data.data;
        console.log(chatHistoryData);
        utilFunctionService.hideLoader();
        this.loading = false;
        this.$cable.unsubscribe("AssessmentAnswerChannel");
        this.emailChain.push({
          fromCharacter: this.studentName,
          toCharacter: this.characterName,
          message: "",
          response: chatHistoryData[chatHistoryData.length - 1].attributes.response[0].response,
          id: this.emailChain.length + 1,
          flagged: chatHistoryData[chatHistoryData.length - 1].attributes.is_flagged
        });
      });
    },
    retryInteraction() {
      let module_id = this.active_data.attributes.learning_object.attributes.learn_mod_id;
      let user_learn_obj_id = this.active_data.id;
      this.$modal.show("dialog", {
        title: "Are you sure you would like to try again? Please note that any work submitted in your previous attempt at this interaction will not be saved. Your assessment on previous attempts will still be available in your final feedback.",
        text: "",
        buttons: [
          {
            title: "Cancel",
            handler: () => {
              this.$modal.hide("dialog");
            },
          },
          {
            title: "Yes I'm sure",
            handler: () => {
              utilFunctionService.showLoader();
              api.retryInteraction(module_id, user_learn_obj_id).then(() => {
                utilFunctionService.hideLoader();
                location.reload();
              }).catch(() => {
                utilFunctionService.hideLoader();
                utilFunctionService.showerr("An error has occurred. Please contact Ametros support for assistance.")
              })
            },
          },
        ],
      });
    },
    loadingRefreshWarning(event) {
      if (this.loading) {
        event.preventDefault();
        if (confirm("Your message is still being assessed. Are you sure you want to refresh?")) {
          window.location.reload()
        } else {
          return false;
        }
      }
    },
  },
  mounted() {
    document.addEventListener("keyup", (event) => {
      if (event.keyCode === 13 && (this.userAnswer !== "" && !this.loading)) {
        this.endEmail();
      }
    });
    window.addEventListener('beforeunload', this.loadingRefreshWarning);
    this.llmComplete = this.active_data.attributes.complete;
    if (this.user_roles.includes('testing-internal')) {
      this.disablePastingText = false;
    }
    let cardDetail =
      this.active_data.attributes.learning_object.attributes.card_detail;
    let userDetail = this.active_data.attributes.user_section.attributes.user.attributes;
    this.characterName = cardDetail.attributes.character_name;
    this.studentName = userDetail.full_name;
    this.emailToCharacter = this.characterName;
    this.emailToCharacterJob = cardDetail.attributes.character_job;
    this.emailFromCharacter = this.studentName;
    this.emailccCharacters = cardDetail.attributes.cc_character[0];
    this.emailIterationsLimit = cardDetail.attributes.email_iteration_limit;
    this.evaluationId = this.active_data.attributes.current_evaluation_id;
    this.getLlmConversation();
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.loadingRefreshWarning);
  },
  channels: {
    AssessmentAnswerChannel: {
      connected() {
        console.log("Email Assessment Answer connected.");
        utilFunctionService.showLoader();
        this.loading = true;
        this.hasStarted = true; 
        let userDetail = this.active_data.attributes.user_section.attributes.user;

        const _fb = new FormData();
        _fb.append("llm_learner_message[body]", this.emailBody);
        _fb.append("llm_learner_message[subject]", this.emailSubject);
        _fb.append("llm_learner_message[user_learn_obj_id]", this.active_data.id);
        _fb.append("llm_learner_message[lo_id]", this.active_data.attributes.learning_object.attributes.learn_mod_id);
        _fb.append("llm_learner_message[student_id]", userDetail.id);
        this.emailBody = "";
        api.submitLlmMessage(_fb).then((res) => {
          console.log(res);
        }).catch((err) => console.log(err));
      },
      received(data) {
        this.refreshLlmConversation();
        console.log("Email Assessment Answer received.");
        console.log(data);
        this.numberOfAttempts = this.numberOfAttempts + 1;
        this.$cable.unsubscribe("AssessmentResultChannel");
      },
    },
    AssessmentResultChannel: {
      connected() {
        console.log("Email Assessment Result connected.");
        let userDetail = this.active_data.attributes.user_section.attributes.user;
        let cardDetail =
        this.active_data.attributes.learning_object.attributes.card_detail;
        this.assessmentLoading = true;
        let payload = {
          student_id: userDetail.id,
          lo_id: this.active_data.attributes.learning_object_id,
          user_learn_obj_id: this.active_data.id,
          data: {
            narrative_knowledge_base: this.narrativeKnowledgeBase,
            general_knowledge_base: this.generalKnowledgeBase,
            prompt_data: cardDetail.prompts,
            number_attempts: this.numberOfAttempts,
            attempt_limit: this.attemptsLimit,
          },
          dialogic: cardDetail.dialogic_switch,
        };
        api.submitLlmEmailAssessment(payload).then((res) => {
          console.log(res);
        });
      },
      received(res) {
        let cardDetail =
          this.active_data.attributes.learning_object.attributes.card_detail
            .attributes;
        console.log("Email Assessment received");
        console.log(res);
        this.evaluationId = res;
        api.getLlmEmailAssessment(res).then((res) => {
          console.log(res);
          this.llmComplete = true;
          this.showFeedback = true;
          this.showAssessment = false;
          this.assessmentLoading = false;
          this.loading = false
          this.$modal.show("llmFeedbackModal", { feedback: res.data.data.attributes, llm_type: cardDetail.llm_type });
          this.$cable.unsubscribe("AssessmentResultChannel");
          utilFunctionService.hideLoader();
        });
      },
    },
  },
};
</script>

<style scoped lang="scss">
.learner-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.messages-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 45px;
}
.bubble {
  border-radius: 15px;
  padding: 16px;
  margin-bottom: 16px;
  max-width: 50%;
}
.learner-bubble {
  background: #3dbc9e;
  color: #faf9f6;
  font-weight: bold;
  justify-self: flex-end;
  align-self: flex-end;
  text-align: right;
  width: fit-content;
}
.robot-bubble {
  background: #3d9bbc;
  color: #faf9f6;
  font-weight: bold;
  justify-self: flex-start;
  align-self: flex-start;
  width: fit-content;
  position: relative;
}
.chat {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 32px;
}
.button {
  padding: 8px;
  border-radius: 5px;
  background: white;
  border: 2px solid green;
  margin-top: 16px;
}
.button:hover {
  background: green;
  color: white;
  cursor: pointer;
  transition: all 0.2s;
}
.button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  width: 100%;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.up-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}
.up-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.feedback {
  width: 50%;
  margin: 25px auto;
  text-align: left;
  line-height: 1.6;
}
.spacer {
  display: block;
  width: 50%;
  margin: 0 auto;
  height: 1px;
  background: black;
}
.header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 32px;
}

.messages-container {
  display: flex;
  width: 100%;
  padding: 10px;
  position: relative;
  min-height: 75px;
  max-height: 450px;
}
.input-area {
  color: black;
  width: 400px;
  min-height: 75px;
  padding: 16px;
  max-height: 450px;
}
.submit-button {
  border: none;
  font-size: 14px;
  padding: 6px 10px;
  border: 1px solid green;
  margin-top: 0;
}

@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin ball {
  @include center;
  content: "";
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: rgb(240, 240, 240);
  z-index: 2;
  margin-top: 4px;
  animation: ball 0.45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
}

/*--------------------
Bounce
--------------------*/
@keyframes bounce {
  0% {
    transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  4.7% {
    transform: matrix3d(0.45, 0, 0, 0, 0, 0.45, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  9.41% {
    transform: matrix3d(0.883, 0, 0, 0, 0, 0.883, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  14.11% {
    transform: matrix3d(1.141, 0, 0, 0, 0, 1.141, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  18.72% {
    transform: matrix3d(1.212, 0, 0, 0, 0, 1.212, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  24.32% {
    transform: matrix3d(1.151, 0, 0, 0, 0, 1.151, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  29.93% {
    transform: matrix3d(1.048, 0, 0, 0, 0, 1.048, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  35.54% {
    transform: matrix3d(0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  41.04% {
    transform: matrix3d(0.961, 0, 0, 0, 0, 0.961, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  52.15% {
    transform: matrix3d(0.991, 0, 0, 0, 0, 0.991, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  63.26% {
    transform: matrix3d(1.007, 0, 0, 0, 0, 1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  85.49% {
    transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}

.loading {
  padding: 16px;
  &::before {
    @include ball;
    border: none;
    animation-delay: 0.15s;
  }

  & span {
    display: block;
    font-size: 0;
    width: 20px;
    height: 10px;
    position: relative;

    &::before {
      @include ball;
      margin-left: -7px;
    }

    &::after {
      @include ball;
      margin-left: 7px;
      animation-delay: 0.3s;
    }
  }
}

@keyframes ball {
  from {
    transform: translateY(0) scaleY(0.8);
  }
  to {
    transform: translateY(-10px);
  }
}

.avatar {
  border-radius: 30px;
  width: 30px;
  height: 30px;
  overflow: hidden;
  margin: 0;
  padding: 0;
  border: 2px solid rgba(255, 255, 255, 0.24);
  margin-right: 16px;
  img {
    width: 100%;
    height: auto;
  }
}

.character-container {
  position: absolute;
  z-index: 1;
  top: -50px;
  left: 0px;
  background: #3d9bbc;
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.character-name {
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  margin: 0;
  padding: 0;
}

.knowledge-base-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
}
.knowledge-base-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.knowledge-base-item h5 {
  font-weight: bold;
}

.kb-btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.text-area {
  width: 400px;
  font-family: sans-serif;
  height: 200px;
  margin-bottom: 18px;
  padding: 8px;
  line-height: 1.6;
}
.prompts-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.side-menu {
  display: flex;
  flex-direction: column;

  width: 20%;
  border-right: 1px solid black;
}
.menu-item {
  width: 85%;
  border: 1px solid black;
  margin-bottom: 0;
  border-radius: 5px;
  list-style-type: none;
  background: white;
  color: black;
  font-weight: bold;
  cursor: pointer;
  padding: 8px;
}
.menu-item:hover {
  background: black;
  color: white;
}
.prompts-edit {
  width: 80%;
  padding: 16px;
}
.prompts-container {
  width: 100%;
  margin-bottom: 32px;
}
.prompts-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
}
.prompts-header h5 {
  font-weight: bold;
}
.kb_form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: flex-start;
}
.kb_form--item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 16px;
}
.kb_form--itemhori {
  flex-direction: row;
}
.kb_form--item label {
  margin-bottom: 8px;
  font-weight: bold;
}

.kb_form--item p {
  margin-left: 16px;
  line-height: 0;
}
.kb_form--item textarea {
  width: 100%;
  font-family: sans-serif;
  height: 100px;
  margin-bottom: 18px;
  padding: 8px;
  line-height: 1.6;
}

.kb_form--item li {
  list-style-type: disc;
}

.kb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
.kb-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
}
.kb-input input {
  width: 50%;
  padding: 8px;
  border-radius: 5px;
}
.btn {
  border-radius: 5px;
  border: 1px solid black;
  color: black;
  font-weight: bold;
  cursor: pointer;
  padding: 8px;
  height: 50px;
}
.btn:hover {
  background: black;
  color: white;
}
.side-prompt {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 16px 0 0;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
}

.edit-kb {
  background: rgb(37, 61, 240);
  border: none;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.edit-kb:hover {
  background: white;
  color: rgb(32, 52, 205);
  border: 1px solid rgb(32, 52, 205);
}

.delete-kb {
  background: rgb(255, 80, 80);
  border: none;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.delete-kb:hover {
  background: white;
  color: rgb(199, 61, 61);
  border: 1px solid rgb(199, 61, 61);
}

.delete-button {
  background: rgb(255, 80, 80);
  color: white;
  font-weight: bold;
  cursor: pointer;
  padding: 5px;
  height: 25px;
  border-radius: 5px;
  border: 1px solid black;
  line-height: 0;
}
.delete-button:hover {
  background: white;
  color: rgb(199, 61, 61);
  border: 1px solid rgb(199, 61, 61);
}
.kb_item--column {
  display: flex;
  flex-direction: column;
}

.learner-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.messages-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1000px;
}
.bubble {
  border-radius: 15px;
  padding: 8px;
  margin-bottom: 16px;
  width: 50%;
}
.learner-bubble {
  background: green;
  color: white;
  font-weight: bold;
  justify-self: flex-end;
  align-self: flex-end;
}
.robot-bubble {
  background: blue;
  color: white;
  font-weight: bold;
  justify-self: flex-start;
  align-self: flex-start;
}
.input-area {
  color: black;
  width: 400px;
}
.chat {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 32px;
}
.button {
  padding: 8px;
  border-radius: 5px;
  background: white;
  border: 2px solid green;
  margin-top: 16px;
}
.button:hover {
  background: green;
  color: white;
  cursor: pointer;
  transition: all 0.2s;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.up-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}
.up-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.feedback {
  width: 50%;
  margin: 25px auto;
  text-align: left;
  line-height: 1.6;
}
.spacer {
  display: block;
  width: 50%;
  margin: 0 auto;
  height: 1px;
  background: black;
}

.prompt-reaction-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.prompt-reaction-container .prompts-body {
  justify-content: flex-start;
  gap: 16px;
}

.prompt-reaction-container button {
  width: 250px;
}

.testing-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 50px;
}

.email-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: #ffffff;
  // box-shadow: 0 12px 20px 0 rgb(0 0 0);
  padding: 40px 47px 60px;
}

.subject-line {
  font-family: "mulibold", sans-serif;
  height: auto;
  padding: 20px 25px;
  border-radius: 2px;
  background-color: #f2f8f6;
  font-size: 14px;
  color: #313030;
  border: 0;
  line-height: 1.71;
  width: 100%;
  margin-bottom: 32px;
}

.email-header {
  width: 100%;
  margin-bottom: 32px;
  padding-bottom: 2em;
  border-bottom: 1px solid #ccc;
}

.email-body {
  width: 100%;
  margin-bottom: 32px;
  padding-bottom: 2em;
}

.email-body .form-control {
  padding: 0;
}

.email-subject {
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 32px;
}

.email-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
}

.email-area {
  width: 100%;
  box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.06)
}

.email-chain {
  width: 100%;
}

.email-divider {
  text-align: center;
  width: 100%;
  font-weight: bold;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding: 8px 0;
  margin-bottom: 32px;
}

.add-prompt-btn {
  width: 80%;
}

.assessmentKBTitle {
  font-weight: bold;
  margin-bottom: 8px;
  border-bottom: 2px solid black;
}

.email-button {
  margin-bottom: 16px;
}

.warning-text {
  color: #d70505;
  font-weight: bold;
  margin-bottom: 16px;
}

.complete-text {
  font-weight: bold;
  font-size: 16px;
}

.character-name {
  font-weight: bold;
  font-size: 14px;
  text-shadow: none;
  color: #000;
}

.character-to {
  font-size: 13px;
  color: #606060;
  margin: 0 28px 0 0;
}

.character-from {
  font-size: 13px;
  color: #606060;
  margin: 0 10px 0 0;
}

.email-entry {
  width: 100%;
  margin: 32px 0;
  background: #ffffff;
}
</style>


